import React from "react";
import { Container } from "./purpose.style";

export function Purpose() {
	return (
		<Container>
			<h2>
        Nosso propósito é criar uma comunidade forte e engajada, atenta às
        mudanças do mercado e aos hábitos de consumo dos clientes, se
        articulando para que todo mundo possa inovar e crescer #junto.
			</h2>
		</Container>
	);
}
